[dir] .table > :not(caption) > * > * {
    padding: 0.72rem 1rem;
}
.cartbtn {
    background: #7367f0;
    padding: 1px 2px;
    color: #ffffff;
    cursor: pointer;
    border-radius: 4px;
  }
.cartnumber {
    padding: 1px 8px;
    color: #000;
    border-radius: 4px;
    border: 1px solid gray;
    margin-right: 3px;
  }
  .MuiTableRow-root:nth-child(even) {
    background-color: #e4e2f3 !important;
  }
  .MuiTableCell-head {
    background-color: #b7b0ff !important;
    color: #000 !important;
    font-weight: bold !important;
    padding: 8px 15px !important;
  }
  .MuiTableCell-root {
    padding: 5px !important;
    border: 1px solid gray;
  }
  .MuiTablePagination-selectLabel {
    margin-bottom: 0px !important;
    word-break: break-all !important;
  }
  .MuiTablePagination-displayedRows {
    margin-bottom: 0px !important;
  }
  .mbb {
    margin-bottom: 15px !important;
  }
  .loader2 {
    position: initial;
  }
  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 80% !important;
    height: 100% !important;
}
[dir] .form-control:disabled, [dir] .form-control[readonly] {
  background-color: #fff !important;
  opacity: 1 !important;
}
.required-field::after {
  content: "*";
  color: red;
  padding-left: 5px;
}
.downloadicon {
  background: transparent;
  color: #000;
  border-radius: 5px;
  padding: 5px 10px;
  border: 1px solid #000;
}
.downloadicon:hover {
  background: #000;
  color: #fff;
  border-radius: 5px;
  padding: 5px 10px;
  border: 1px solid #000;
}
.multiline-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap; /* let the text wrap preserving spaces */
  width: 60px;
}
.multiline-ellipsis1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap; /* let the text wrap preserving spaces */
  width: 80px;
}
div:where(.swal2-container) {
  z-index: 9999 !important;
}
.css-ubpweo-MuiPaper-root-MuiPopover-paper {
  padding-left: 10px;
}
.react-tel-input .form-control {
  width: 100%;
}
.css-hyum1k-MuiToolbar-root {
  padding-left: 10px !important;
  padding-right: 24px;
}